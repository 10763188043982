import React from 'react'
import About from './About'
import Navbar from './Navbar'
import Expertise from './Expertise'
import Service from './Service'
import Projects from './Projects'
import Team from './Team'
import Testimonials from './Testimonials'
import Contact from './Contact'
import Map from './Map'

const Main = () => {
  return (
    <>
      {/* eslint-disable jsx-a11y/anchor-is-valid */}
      <Navbar />
      <About></About>
      <Expertise></Expertise>
      <Service></Service>
      <Projects />
      <Team />
      <Testimonials />
      <Contact />
      <Map/>
    </>
  );
}

export default Main