import React, { useEffect } from 'react'
import Typed from 'typed.js';

const Navbar = () => {
  useEffect(() => {
    // Target the element where you want to display the typing animation
    const targetElement = document.querySelector(".typed-text-output");

    // Strings to be typed
    const strings = [
      "Web Designer",
      "Web Developer",
      "Front End Developer",
      "Apps Designer",
      "Apps Developer",
    ];

    // Initialize Typed instance
    const typed = new Typed(".typed-text-output", {
      strings: strings,
      typeSpeed: 50, // typing speed in milliseconds
      backSpeed: 25, // backspacing speed in milliseconds
      startDelay: 500, // delay before starting to type
      backDelay: 1500, // delay before starting to backspace
      loop: true, // set to true for infinite loop
      showCursor: true, // show blinking cursor
      cursorChar: "|", // cursor character
    });

    // Cleanup function
    return () => {
      typed.destroy(); // Destroy the Typed instance when the component unmounts
    };
  }, []); // Run this effect only once when the component mounts

  return (
    <>
      <nav
        className="navbar navbar-expand-lg bg-white navbar-light fixed-top shadow py-lg-0 px-4 px-lg-5 wow fadeIn"
        data-wow-delay="0.1s"
      >
        <a href="index.html" className="navbar-brand d-block d-lg-none">
          <h1 className="text-primary fw-bold m-0">ProMan</h1>
        </a>
        <button
          type="button"
          className="navbar-toggler"
          data-bs-toggle="collapse"
          data-bs-target="#navbarCollapse"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="collapse navbar-collapse justify-content-between py-4 py-lg-0"
          id="navbarCollapse"
        >
          <div className="navbar-nav ms-auto py-0">
            <a href="#home" className="nav-item nav-link active">
              Home
            </a>
            <a href="#about" className="nav-item nav-link">
              About
            </a>
            <a href="#skill" className="nav-item nav-link">
              Skills
            </a>
            <a href="#service" className="nav-item nav-link">
              Services
            </a>
          </div>
          <a
            href="index.html"
            className="navbar-brand bg-secondary py-3 px-4 mx-3 d-none d-lg-block"
          >
            <h1 className="text-primary fw-bold m-0">ProMan</h1>
          </a>
          <div className="navbar-nav me-auto py-0">
            <a href="#project" className="nav-item nav-link">
              Projects
            </a>
            <a href="#team" className="nav-item nav-link">
              Team
            </a>
            <a href="#testimonial" className="nav-item nav-link">
              Testimonial
            </a>
            <a href="#contact" className="nav-item nav-link">
              Contact
            </a>
          </div>
        </div>
      </nav>
      <div className="container-fluid bg-light my-6 mt-0" id="home">
        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-6 py-6 pb-0 pt-lg-0">
              <h3 className="text-primary mb-3">I'm</h3>
              <h1 className="display-3 mb-3">Kate Winslet</h1>
              <h2 className="typed-text-output d-inline"></h2>
              <div className="typed-text d-none">
                Web Designer, Web Developer, Front End Developer, Apps Designer,
                Apps Developer
              </div>
              <div className="d-flex align-items-center pt-5">
                <a href="" className="btn btn-primary py-3 px-4 me-5">
                  Download CV
                </a>
                <button
                  type="button"
                  className="btn-play"
                  data-bs-toggle="modal"
                  data-src="https://www.youtube.com/embed/DWRcNpR6Kdc"
                  data-bs-target="#videoModal"
                >
                  <span></span>
                </button>
                <h5 className="ms-4 mb-0 d-none d-sm-block">Play Video</h5>
              </div>
            </div>
            <div className="col-lg-6">
              <img
                className="img-fluid"
                src="../assets/img/profile.png"
                alt="profile.png"
              />
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Video Modal Start --> */}
      <div
        className="modal modal-video fade"
        id="videoModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content rounded-0">
            <div className="modal-header">
              <h3 className="modal-title" id="exampleModalLabel">
                Youtube Video
              </h3>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {/* <!-- 16:9 aspect ratio --> */}
              <div className="ratio ratio-16x9">
                <iframe
                  className="embed-responsive-item"
                  src=""
                  id="video"
                  allowFullScreen
                  allowscriptaccess="always"
                  allow="autoplay"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Video Modal End --> */}
    </>
  );
}

export default Navbar