import Main from "./components/Main";


function App() {
  return (
    <>
    <Main></Main>
      
    </>
  );
}

export default App;
