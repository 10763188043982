import React, { useState } from "react";
import emailjs from "emailjs-com";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });
  const [error, setError] = useState({});

  const changeHandler = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const varifydata = () => {
    let errors = {};
    if (formData.name.length === 0) {
      errors.name = "Please, Write Your Name.";
    }
    if (!formData.email.includes("@")) {
      errors.email = "Enter Valid Email.";
    }
    if (formData.message.length < 10) {
      errors.message = "Please, Add Some More Details.";
    }
    if (formData.subject.length === 0) {
      errors.subject = "Please, Add Subject.";
    }
    setError(errors);
    return Object.keys(errors).length === 0;
  };

  const formSubmit = async (e) => {
    e.preventDefault();
    if (varifydata()) {
      const templateParams = {
        to_name: "Dhaval",
        from_name: formData.name,
        subject: formData.subject,
        message: formData.message,
        email: formData.email,
      };
      try {
        const response = await emailjs.send(
          "service_mr0l3uq",
          "template_8pnpn2j",
          templateParams,
          "8ODvLo608Wt79FPPT"
        );
        if (response.status === 200) {
          setError({ mess: "Email Sent Successfully." });
        } else {
            setError({ mess: "Somthing Went Wronge." });
            
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <>
      {/* eslint-disable jsx-a11y/anchor-is-valid */}
      <div className="container-xxl pb-5" id="contact">
        <div className="container py-5">
          <div className="row g-5 mb-5 wow fadeInUp" data-wow-delay="0.1s">
            <div className="col-lg-6">
              <h1 className="display-5 mb-0">Let's Work Together</h1>
            </div>
            <div className="col-lg-6 text-lg-end">
              <a className="btn btn-primary py-3 px-5" href="#">
                Say Hello
              </a>
            </div>
          </div>
          <div className="row g-5">
            <div
              className="col-lg-5 col-md-6 wow fadeInUp"
              data-wow-delay="0.1s"
            >
              <p className="mb-2">My office:</p>
              <h3 className="fw-bold">123 Street, New York, USA</h3>
              <hr className="w-100" />
              <p className="mb-2">Call me:</p>
              <h3 className="fw-bold">+012 345 6789</h3>
              <hr className="w-100" />
              <p className="mb-2">Mail me:</p>
              <h3 className="fw-bold">info@example.com</h3>
              <hr className="w-100" />
              <p className="mb-2">Follow me:</p>
              <div className="d-flex pt-2">
                <a className="btn btn-square btn-primary me-2" href="">
                  <i className="fab fa-twitter"></i>
                </a>
                <a className="btn btn-square btn-primary me-2" href="">
                  <i className="fab fa-facebook-f"></i>
                </a>
                <a className="btn btn-square btn-primary me-2" href="">
                  <i className="fab fa-youtube"></i>
                </a>
                <a className="btn btn-square btn-primary me-2" href="">
                  <i className="fab fa-linkedin-in"></i>
                </a>
              </div>
            </div>
            <div
              className="col-lg-7 col-md-6 wow fadeInUp"
              data-wow-delay="0.5s"
            >
              <p className="mb-4">
                The contact form is currently inactive. Get a functional and
                working contact form with Ajax & PHP in a few minutes. Just copy
                and paste the files, add a little code and you're done.{" "}
                <a href="https://htmlcodex.com/contact-form">Download Now</a>.
              </p>
              <form onSubmit={formSubmit}>
                {error.mess && (
                  <h4 style={{ color: "green" }}>{error.mess}</h4>
                )}
                <div className="row g-3">
                  <div className="col-md-6">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        onChange={changeHandler}
                        value={formData.name}
                        placeholder="Your Name"
                      />
                      <label htmlFor="name">Your Name</label>
                      {error.name && (
                        <h6 style={{ color: "red" }}>{error.name}</h6>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-floating">
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        onChange={changeHandler}
                        value={formData.email}
                        name="email"
                        placeholder="Your Email"
                      />
                      <label htmlFor="email">Your Email</label>
                      {error.email && (
                        <h6 style={{ color: "red" }}>{error.email}</h6>
                      )}
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-floating">
                      <input
                        type="text"
                        className="form-control"
                        id="subject"
                        name="subject"
                        onChange={changeHandler}
                        value={formData.subject}
                        placeholder="Subject"
                      />
                      <label htmlFor="subject">Subject</label>
                      {error.subject && (
                        <h6 style={{ color: "red" }}>{error.subject}</h6>
                      )}
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="form-floating">
                      <textarea
                        className="form-control"
                        placeholder="Leave a message here"
                        id="message"
                        onChange={changeHandler}
                        value={formData.message}
                        name="message"
                        style={{ height: "100px" }}
                      ></textarea>
                      <label htmlFor="message">Message</label>
                      {error.message && (
                        <h6 style={{ color: "red" }}>{error.message}</h6>
                      )}
                    </div>
                  </div>
                  <div className="col-12">
                    <button className="btn btn-primary py-3 px-5" type="submit">
                      Send Message
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Contact;
